
*, *:before, *:after {
  box-sizing: border-box;
  text-decoration: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  scroll-behavior: smooth;
}


.App {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  height: 100%;
  width: 100%;
  /* border: 2px solid orange; */
}


@media (prefers-reduced-motion: no-preference) {
  
}





@media (max-width: 800px) {
  .team-container {
    flex-direction: column;
    flex-wrap: wrap;
  }
}



