#Main {
    height: 80vh;
    width: 100%;
  }

.teams_container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-evenly;
    max-height: 80%;
    gap: 1%;
}

@media screen and (max-width: 768px) {
    .teams_container {
        flex-direction: column;
        flex-wrap: wrap;
    }
    
  }

 

  .home-background-image {
    /* background-image: url('../assets/Images/wave-haikei.svg');
    background-size: cover; */
    background-color: palevioletred;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
  }
 